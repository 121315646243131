import PropTypes from 'prop-types'
import classNames from 'classnames'

import rows from './rows.json'

import s from './SplashText.module.css'

function SplashText({ location, className }) {
  if (!location) return null

  return (
    <div
      className={classNames(s.container, className, {
        [s.containerActive]: location !== '',
      })}
    >
      {rows.map((row, i) => (
        <div key={i} className={s.row}>
          {row.map((name, i) => (
            <span
              key={`${name}${i}`}
              className={classNames(s.text, {
                [s.textActive]: name === location,
              })}
            >
              {name}
            </span>
          ))}
        </div>
      ))}
    </div>
  )
}

SplashText.propTypes = {
  location: PropTypes.string,
  className: PropTypes.string,
}
SplashText.defaultProps = {
  location: '',
  className: '',
}

export default SplashText
